define('adminui/services/user-search', ['exports', 'ember', 'adminui/adapters/search-user'], function (exports, _ember, _adminuiAdaptersSearchUser) {
	exports['default'] = _ember['default'].Service.extend({

		searchUserAdapter: null,

		init: function init() {
			this._super.apply(this, arguments);
			this.set('searchUserAdapter', _adminuiAdaptersSearchUser['default'].create(_ember['default'].getOwner(this).ownerInjection()));
		},

		searchUsers: function searchUsers(selectedCriteria, searchValue) {
			var service = this;
			return new _ember['default'].RSVP.Promise(function (resolve, reject) {
				service.get('searchUserAdapter').getMatchingUsers(selectedCriteria, searchValue).then(function (response) {
					resolve(response);
				}, reject);
			});
		}
	});
});