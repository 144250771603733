define('llama-table/components/llama-embed/component', ['exports', 'ember', 'llama-table/components/llama-embed/template'], function (exports, _ember, _llamaTableComponentsLlamaEmbedTemplate) {
	'use strict';

	var get = _ember['default'].get;
	var observer = _ember['default'].observer;
	var computed = _ember['default'].computed;
	var alias = computed.alias;

	var LlamaEmbed = _ember['default'].Component.extend({
		layout: _llamaTableComponentsLlamaEmbedTemplate['default'],
		classNames: 'llama-embed',
		height: alias('row.subcontentHeight'),

		row: null,

		rows: alias('root.sortedRows'),

		didInsertElement: function didInsertElement() {
			this._super();
			this.updateOffsetTop();
			this.updateHeight();
		},

		calculateRowHeight: function calculateRowHeight(row) {
			var result = get(row, 'height');
			if (get(row, 'isExpanded')) {
				result += get(row, 'subcontentHeight') || 0;
			}
			return result;
		},

		offsetTop: null,

		rowHeightHandler: function rowHeightHandler() {
			var _this = this;

			var sortedRows = this.get('rows');
			var row = this.get('row');
			var index = sortedRows.indexOf(row);
			var previous = sortedRows.slice(0, index);
			var previousHeight = previous.reduce(function (total, row) {
				return total + _this.calculateRowHeight(row);
			}, 0);
			var thisHeight = get(row, 'height');
			var offsetTop = previousHeight + thisHeight;
			this.set('offsetTop', offsetTop);
		},

		rowHeightObserver: observer('rows.@each.isExpanded', 'rows.@each.height', 'rows.@each.subcontentHeight', function () {
			_ember['default'].run.once(this, this.rowHeightHandler);
		}),

		updateOffsetTop: observer('offsetTop', function () {
			var $embed = _ember['default'].$(this.$());
			$embed.css('top', this.get('offsetTop'));
		}),

		updateHeight: observer('height', function () {
			var $embed = _ember['default'].$(this.$());
			$embed.css('height', this.get('height'));
		})
	});

	exports['default'] = LlamaEmbed;
});