define('adminui/services/delete-undelete', ['exports', 'ember', 'adminui/adapters/delete-undelete'], function (exports, _ember, _adminuiAdaptersDeleteUndelete) {
	exports['default'] = _ember['default'].Service.extend({

		deleteUndeleteAdapter: null,

		init: function init() {
			this._super.apply(this, arguments);
			this.set('deleteUndeleteAdapter', _adminuiAdaptersDeleteUndelete['default'].create(_ember['default'].getOwner(this).ownerInjection()));
		},

		saveContent: function saveContent(id, contentItem) {
			var service = this;
			return new _ember['default'].RSVP.Promise(function (resolve, reject) {
				service.get('deleteUndeleteAdapter').saveContent(id, contentItem).then(function (response) {
					resolve(response);
				}, reject);
			});
		}
	});
});