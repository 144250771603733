define('llama-table/components/llama-footer-columngroup/component', ['exports', 'ember', 'llama-table/components/llama-columngroup/component', 'llama-table/components/llama-footer-columngroup/template'], function (exports, _ember, _llamaTableComponentsLlamaColumngroupComponent, _llamaTableComponentsLlamaFooterColumngroupTemplate) {
	'use strict';

	var alias = _ember['default'].computed.alias;

	var LlamaFooterColumngroup = _llamaTableComponentsLlamaColumngroupComponent['default'].extend({
		layout: _llamaTableComponentsLlamaFooterColumngroupTemplate['default'],
		classNames: 'llama-footer-columngroup',
		itemViewClass: alias('root.FooterColumnView'),

		columns: null,
		data: null
	});

	exports['default'] = LlamaFooterColumngroup;
});