define('llama-table/components/llama-footer-column/component', ['exports', 'ember', 'llama-table/components/llama-column/component', 'llama-table/components/llama-footer-column/template'], function (exports, _ember, _llamaTableComponentsLlamaColumnComponent, _llamaTableComponentsLlamaFooterColumnTemplate) {
	'use strict';

	var _get = _ember['default'].get;
	var set = _ember['default'].set;
	var computed = _ember['default'].computed;

	var LlamaFooterColumn = _llamaTableComponentsLlamaColumnComponent['default'].extend({
		layout: _llamaTableComponentsLlamaFooterColumnTemplate['default'],
		classNames: 'llama-footer-column',

		data: null,
		column: null,

		itemViewClass: computed({
			get: function get() {
				var controller = this.get('root');
				var column = this.get('column');
				var type = _get(column, 'type');
				return controller.getCellType(type);
			}
		}),

		createChildView: function createChildView(View, attrs) {
			var data = this.get('data');
			var column = this.get('column');
			set(attrs, 'root', this.get('root'));
			set(attrs, 'row', data);
			set(attrs, 'column', column);
			set(attrs, 'isFooter', true);
			return this._super(View, attrs);
		}
	});

	exports['default'] = LlamaFooterColumn;
});