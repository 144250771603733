define('adminui/routes/dashboard', ['exports', 'ember', 'ember-simple-auth/mixins/authenticated-route-mixin'], function (exports, _ember, _emberSimpleAuthMixinsAuthenticatedRouteMixin) {
	exports['default'] = _ember['default'].Route.extend(_emberSimpleAuthMixinsAuthenticatedRouteMixin['default'], {

		session: _ember['default'].inject.service(),

		displayName: _ember['default'].computed('session', function () {
			var sessionData = this.get('session').get('data');
			return sessionData.authenticated.displayName;
		}),

		model: function model() {
			return this.get('displayName');
		}
	});
});