define("llama-table/components/llama-footer-columngroup/template", ["exports"], function (exports) {
  "use strict";

  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      return {
        meta: {
          "revision": "Ember@2.7.3",
          "loc": {
            "source": null,
            "start": {
              "line": 1,
              "column": 0
            },
            "end": {
              "line": 10,
              "column": 0
            }
          },
          "moduleName": "modules/llama-table/components/llama-footer-columngroup/template.hbs"
        },
        isEmpty: false,
        arity: 1,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("	");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
          return morphs;
        },
        statements: [["inline", "component", [["get", "itemViewClass", ["loc", [null, [2, 13], [2, 26]]], 0, 0, 0, 0]], ["root", ["subexpr", "@mut", [["get", "root", ["loc", [null, [3, 7], [3, 11]]], 0, 0, 0, 0]], [], [], 0, 0], "rows", ["subexpr", "@mut", [["get", "rows", ["loc", [null, [4, 7], [4, 11]]], 0, 0, 0, 0]], [], [], 0, 0], "columns", ["subexpr", "@mut", [["get", "columns", ["loc", [null, [5, 10], [5, 17]]], 0, 0, 0, 0]], [], [], 0, 0], "column", ["subexpr", "@mut", [["get", "column", ["loc", [null, [6, 9], [6, 15]]], 0, 0, 0, 0]], [], [], 0, 0], "content", ["subexpr", "@mut", [["get", "column", ["loc", [null, [7, 10], [7, 16]]], 0, 0, 0, 0]], [], [], 0, 0], "data", ["subexpr", "@mut", [["get", "data", ["loc", [null, [8, 7], [8, 11]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [2, 1], [9, 3]]], 0, 0]],
        locals: ["column"],
        templates: []
      };
    })();
    return {
      meta: {
        "revision": "Ember@2.7.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 11,
            "column": 0
          }
        },
        "moduleName": "modules/llama-table/components/llama-footer-columngroup/template.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(1);
        morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
        dom.insertBoundary(fragment, 0);
        dom.insertBoundary(fragment, null);
        return morphs;
      },
      statements: [["block", "each", [["get", "columns", ["loc", [null, [1, 8], [1, 15]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [1, 0], [10, 9]]]]],
      locals: [],
      templates: [child0]
    };
  })());
});