define('adminui/services/publish-unpublish', ['exports', 'ember', 'adminui/adapters/publish-unpublish'], function (exports, _ember, _adminuiAdaptersPublishUnpublish) {
	exports['default'] = _ember['default'].Service.extend({

		publishUnpublishAdapter: null,

		init: function init() {
			this._super.apply(this, arguments);
			this.set('publishUnpublishAdapter', _adminuiAdaptersPublishUnpublish['default'].create(_ember['default'].getOwner(this).ownerInjection()));
		},

		saveContent: function saveContent(id, contentItem) {
			var service = this;
			return new _ember['default'].RSVP.Promise(function (resolve, reject) {
				service.get('publishUnpublishAdapter').saveContent(id, contentItem).then(function (response) {
					resolve(response);
				}, reject);
			});
		}
	});
});