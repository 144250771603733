define('llama-table/computed', ['exports', 'ember'], function (exports, _ember) {
	'use strict';

	exports.defaultValue = defaultValue;
	exports.eq = eq;
	exports.join = join;
	exports.makeArray = makeArray;

	var computed = _ember['default'].computed;
	var _get = _ember['default'].get;
	var _set = _ember['default'].set;
	var isBlank = _ember['default'].isBlank;

	function defaultValue(watchKey, defValue) {
		return computed(watchKey, {
			set: function set(setKey, value) {
				try {
					_set(this, watchKey, value);
				} catch (e) {
					// swallow
				}
				return value;
			},
			get: function get(value) {
				// getter
				value = _get(this, watchKey);
				return isBlank(value) ? defValue : value;
			}
		});
	}

	function eq(left, right) {
		return computed(left, right, {
			get: function get() {
				return this.get(left) === this.get(right);
			}
		});
	}

	function join(watchKey) {
		var separator = arguments.length <= 1 || arguments[1] === undefined ? ',' : arguments[1];

		return computed(watchKey, {
			get: function get() {
				var value = _ember['default'].makeArray(this.get(watchKey));
				var strings = value.map(String);
				var result = strings.join(separator);
				return result;
			}
		});
	}

	function makeArray(watchKey) {
		return computed(watchKey, {
			get: function get() {
				return _ember['default'].A(this.get(watchKey));
			}
		});
	}

	exports['default'] = {
		defaultValue: defaultValue,
		eq: eq,
		join: join,
		makeArray: makeArray
	};
});