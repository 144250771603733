define('adminui/authenticators/admin-auth', ['exports', 'ember', 'ember-simple-auth/authenticators/base'], function (exports, _ember, _emberSimpleAuthAuthenticatorsBase) {
	exports['default'] = _emberSimpleAuthAuthenticatorsBase['default'].extend({

		authentication: _ember['default'].inject.service(),

		restore: function restore(data) {
			return _ember['default'].RSVP.resolve(data);
		},

		authenticate: function authenticate(options) {
			return this.get('authentication').authenticateWithCredentials(options.username, options.password);
		}
	});
});