define('llama-table/components/llama-column/component', ['exports', 'ember', 'llama-table/computed'], function (exports, _ember, _llamaTableComputed) {
	'use strict';

	var on = _ember['default'].on;
	var observer = _ember['default'].observer;
	var computed = _ember['default'].computed;
	var alias = computed.alias;
	var bool = computed.bool;
	var not = computed.not;
	var filterBy = computed.filterBy;

	var LlamaColumn = _ember['default'].Component.extend({
		classNames: 'llama-column',
		classNameBindings: ['textAlignClass', 'columnClassNames', 'isVisible', 'isHidden'],
		attributeBindings: ['columnName:data-column-name', 'columnType:data-column-type'],
		width: alias('column.width'),
		isHidden: bool('column.isHidden'),
		isVisible: not('isHidden'),
		columnClassNames: (0, _llamaTableComputed.join)('column.classNames', ' '),
		columnName: alias('column.name'),
		columnType: alias('column.type'),

		rows: null,
		column: null,

		config: computed('columnType', 'columnName', 'root.config.types', {
			get: function get() {
				var types = this.get('root.config.types');
				if (!_ember['default'].isArray(types)) {
					return null;
				}
				var name = this.get('columnType') || this.get('columnName');
				var type = _ember['default'].A(types).findBy('name', name);
				return type;
			}
		}),

		setWidth: on('didInsertElement', observer('width', function () {
			var width = this.get('width');
			var $column = _ember['default'].$(this.$());
			$column.width(width);
		})),

		cellViews: filterBy('childViews', 'isVisible', true),

		textAlignClass: computed('config.textAlign', 'column.textAlign', function () {
			var textAlign = this.get('config.textAlign') || this.get('column.textAlign');
			switch (textAlign) {
				case 'left':
					return 'text-left';
				case 'right':
					return 'text-right';
				case 'center':
					return 'text-center';
				case 'justify':
					return 'text-justify';
			}
		})
	});

	exports['default'] = LlamaColumn;
});