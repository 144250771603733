define('llama-table/controllers/column', ['exports', 'ember', 'llama-table/computed'], function (exports, _ember, _llamaTableComputed) {
  'use strict';

  var computed = _ember['default'].computed;
  var alias = computed.alias;

  /**
   * Manages column attributes. All properties are JSON-encodable so that column
   *   configuration and updates can be embedded or transferred between entities.
   * @module controllers
   * @constructor
   * @class ColumnController
   * @extends Ember.ObjectProxy
   */
  var ColumnController = _ember['default'].ObjectProxy.extend({
    /**
     * Column name. Should match with property name of record to display.
     * @property {String} name
     */
    name: alias('model.name'),

    /**
     * Text label. Shows in column header.
     * @property {String} label
     */
    label: alias('model.label'),

    /**
     * Override column ordering with this property.
     * @property {Number} order
     */
    order: alias('model.order'),

    /**
     * Column type which provides extra column configuration.
     * @property {String} type
     */
    type: alias('model.type'),

    /**
     * Classes to apply to each column. Can be an array of strings or a single
     *   string separated by spaces.
     * @property {String|String[]} classNames
     * @optional
     */
    classNames: alias('model.classNames'),

    /**
     * Properties to observe. By default, will observe the property specified by
     *   `name`.
     * @property {String[]} observes
     * @optional
     */
    observes: alias('model.observes'),

    /**
     * Current width of the column in pixels.
     * @property {Number} width
     * @optional
     * @default 200
     */
    width: (0, _llamaTableComputed.defaultValue)('model.width', 200),

    /**
     * Minimum width to allow this column to be resized to.
     * @property {Number} minWidth
     * @optional
     * @default 50
     */
    minWidth: (0, _llamaTableComputed.defaultValue)('model.minWidth', 50),

    /**
     * Maximum width to allow this column to be resized to.
     * @property {Number} maxWidth
     * @optional
     */
    maxWidth: alias('model.maxWidth'),

    /**
     * Column values can be sorted by clicking column header.
     * @property {Boolean} isSortable
     * @optional
     * @default true
     */
    isSortable: (0, _llamaTableComputed.defaultValue)('model.isSortable', true),

    /**
     * Get's the key name to use for sorting
     * @property {String}
     */
    sortBy: computed('model.sortBy', 'name', function () {
      var sortBy = this.get('model.sortBy');

      if (_ember['default'].isEmpty(sortBy)) {
        return this.get('name');
      }

      return sortBy;
    }),

    /**
     * Values in the column can be edited. NOT YET IMPLEMENTED.
     * @property {Boolean} isEditable
     * @optional
     * @default false
     */
    isEditable: (0, _llamaTableComputed.defaultValue)('model.isEditable', false),

    /**
     * Column is hidden from view.
     * @property {Boolean} isHidden
     * @optional
     * @default false
     */
    isHidden: (0, _llamaTableComputed.defaultValue)('model.isHidden', false),

    /**
     * Column can be resized via the drag handle.
     * @property {Boolean} isResizable
     * @optional
     * @default true
     */
    isResizable: (0, _llamaTableComputed.defaultValue)('model.isResizable', true),

    /**
     * Cells will fire a `cellClick` event from the component when clicked.
     * @property {Boolean} isClickable
     * @optional
     * @default false
     */
    isClickable: (0, _llamaTableComputed.defaultValue)('model.isClickable', false),

    /**
     * Show the label text in the column header.
     * @property {Boolean} showLabel
     * @optional
     * @default true
     */
    showLabel: (0, _llamaTableComputed.defaultValue)('model.showLabel', true),

    /**
     * Adds a `text-*` class to the column to indicate alignment. Can be used to
     *   right-align numeric values.
     * @property {String} textAlign
     * @optional
     * @default "left"
     */
    textAlign: computed('model.textAlign', 'type', {
      get: function get() {
        var value = this.get('model.textAlign');
        if (!_ember['default'].isEmpty(value)) {
          return value;
        }
        if (this.get('type') === 'number') {
          return 'right';
        }
        return 'left';
      }
    }),

    /**
     * Available to formatted cells. Optionally defines a mask or pattern for
     *   displaying data (for example, numbers or dates).
     * @property {String} format
     * @optional
     */
    format: alias('model.format')
  });

  exports['default'] = ColumnController;
});