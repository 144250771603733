define('adminui/controllers/dashboard/bulkeditcontent', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Controller.extend({
    cont: {},

    showMetadata: false,
    showTaxonomy: false,
    showSubject: false,
    showAccess: false,
    showTags: false,
    data: null,
    jsonFormat: function jsonFormat() {
      var obj = this.get('cont');
      this.set('metadata', JSON.stringify(obj.metadata, undefined, 4));
      this.set('taxonomy', JSON.stringify(obj.taxonomy, undefined, 4));
      this.set('accessibility', JSON.stringify(obj.accessibility, undefined, 4));
      this.set('tags', JSON.stringify(obj.editorial_tags, undefined, 4));
    },
    saveDisable: false,
    init: function init() {

      this.jsonFormat();
    },

    actions: {
      cancelAction: function cancelAction() {
        this.transitionToRoute('dashboard.searchcontent');
      },
      navigateDashboard: function navigateDashboard() {
        this.transitionToRoute('dashboard');
      },
      submitAction: function submitAction(payload) {
        console.log("Bulk Edit Submit action called");
      },
      setdata: function setdata(value) {
        this.set('data', value);
      },
      validate: function validate(value) {
        var data = this.get('data');
        try {
          JSON.parse(value);
          if (data == 'metadata') {
            this.set('showMetadata', false);
          }
          if (data == 'taxonomy') {
            this.set('showTaxonomy', false);
          }
          if (data == 'subject') {
            this.set('showSubject', false);
          }
          if (data == 'accessibility') {
            this.set('showAccess', false);
          }
          if (data == 'tags') {
            this.set('showTags', false);
          }
          if (this.get('showMetadata') == false && this.get('showTaxonomy') == false && this.get('showSubject') == false && this.get('showAccess') == false && this.get('showTags') == false) {
            this.set('saveDisable', false);
          }
        } catch (e) {
          if (data == 'metadata') {
            this.set('showMetadata', true);
          }
          if (data == 'taxonomy') {
            this.set('showTaxonomy', true);
          }
          if (data == 'subject') {
            this.set('showSubject', true);
          }
          if (data == 'accessibility') {
            this.set('showAccess', true);
          }
          if (data == 'tags') {
            this.set('showTags', true);
          }
          if (this.get('showMetadata') == true || this.get('showTaxonomy') == true || this.get('showSubject') == true || this.get('showAccess') == true || this.get('showTags') == true) {
            this.set('saveDisable', true);
          }
        }
      }
    }
  });
});