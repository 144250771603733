define("adminui/components/gooru-form", ["exports", "ember"], function (exports, _ember) {
    var master_schema = {
        "schema": {
            "fields": [{
                "type": "enum",
                "title": "Content Subformat",
                "id": "content_subformat",
                "dataVal": "",
                hasErrors: true,
                errorMessage: "Required field",
                editMode: "bulk",
                "required": true
            }, {
                "type": "enum",
                "title": "Language",
                "id": "language",
                "dataVal": "",
                hasErrors: false,
                errorMessage: "Please enter valid data",
                editMode: "bulk",
                "required": false

            }, {
                "type": "enum",
                "title": "Audience",
                "id": "audience",
                "dataVal": "",
                hasErrors: true,
                errorMessage: "Please enter valid data",
                editMode: "bulk",
                "required": false
            }, {
                "type": "enum",
                "title": "Educational Use",
                "id": "educational_use",
                "dataVal": "",
                hasErrors: true,
                errorMessage: "Please enter valid data",
                editMode: "bulk",
                "required": false
            }, {
                "type": "json",
                "title": "Subject",
                "id": "subject",
                "dataVal": "",
                hasErrors: true,
                errorMessage: "JSON format incorrect!!!",
                editMode: "bulk",
                "required": false
            }, {
                "type": "json",
                "title": "Metadata",
                "id": "metadata",
                "dataVal": "Prasad",
                hasErrors: false,
                errorMessage: "JSON format incorrect!!!",
                editMode: "bulk",
                "required": false
            }, {
                "type": "json",
                "title": "Taxonomy",
                "id": "taxonomy",
                "dataVal": "Prasad",
                hasErrors: false,
                errorMessage: "JSON format incorrect!!!",
                editMode: "bulk",
                "required": false
            }, {
                "type": "json",
                "title": "Accessibility",
                "id": "accessibility",
                "dataVal": "Prasad",
                hasErrors: false,
                errorMessage: "JSON format incorrect!!!",
                editMode: "bulk",
                "required": false
            }, {
                "type": "json",
                "title": "Editorial Tags",
                "id": "editorial_tags",
                "dataVal": "Prasad",
                hasErrors: false,
                errorMessage: "JSON format incorrect!!!",
                editMode: "bulk",
                "required": false
            }, {
                "type": "json",
                "title": "Copyright Owner",
                "id": "copyright_owner",
                "dataVal": "Prasad",
                hasErrors: false,
                errorMessage: "JSON format incorrect!!!",
                editMode: "bulk",
                "required": false
            }, {
                "type": "string",
                "title": "Title",
                "id": "title",
                "dataVal": "",
                hasErrors: true,
                errorMessage: "Required field",
                editMode: "single",
                "required": true
            }, {
                "type": "string",
                "title": "Url",
                "id": "url",
                "dataVal": "Prasad",
                hasErrors: false,
                errorMessage: "Please enter valid data",
                editMode: "single",
                "required": false
            }, {
                "type": "string",
                "title": "Creator id",
                "id": "creator_id",
                "dataVal": "Prasad",
                hasErrors: false,
                errorMessage: "Required field",
                editMode: "single",
                "required": true
            }, {
                "type": "boolean",
                "title": "Is Remote?",
                "id": "is_remote",
                "dataVal": "Prasad",
                hasErrors: false,
                errorMessage: "Required field",
                editMode: "single",
                "required": true
            }, {
                "type": "boolean",
                "title": "Is Broken?",
                "id": "is_broken",
                "dataVal": "Prasad",
                hasErrors: false,
                errorMessage: "Required field",
                editMode: "single",
                "required": true
            }, {
                "type": "boolean",
                "title": "Is Iframe Breaker?",
                "id": "display_guide.is_iframe_breaker",
                "dataVal": "Prasad",
                hasErrors: false,
                errorMessage: "Required field",
                editMode: "single",
                "required": true
            },
            // {
            //     "type": "uuid",
            //     "title": "Narration",
            //     "id" : "narration",
            //     "dataVal": "Prasad",
            //     hasErrors: false,
            //     errorMessage: "JSON format incorrect!!!",
            //     editMode: "single",
            //     "required": false
            // },
            {
                "type": "json",
                "title": "Description",
                "id": "description",
                "dataVal": "Prasad",
                hasErrors: false,
                errorMessage: "JSON format incorrect!!!",
                editMode: "single",
                "required": false
            }, {
                "type": "enum",
                "title": "Content Subformat",
                "id": "content_subformat",
                "dataVal": "Prasad",
                hasErrors: false,
                errorMessage: "Required field",
                editMode: "single",
                "required": true
            }, {
                "type": "json",
                "title": "Metadata",
                "id": "metadata",
                "dataVal": "Prasad",
                hasErrors: false,
                errorMessage: "JSON format incorrect!!!",
                editMode: "single",
                "required": false
            }, {
                "type": "json",
                "title": "Taxonomy",
                "id": "taxonomy",
                "dataVal": "Prasad",
                hasErrors: false,
                errorMessage: "JSON format incorrect!!!",
                editMode: "single",
                "required": false
            }, {
                "type": "boolean",
                "title": "Is Copyright Owner?",
                "id": "is_copyright_owner",
                "dataVal": "",
                hasErrors: false,
                errorMessage: "",
                editMode: "single",
                "required": false
            }, {
                "type": "json",
                "title": "Copyright Owner",
                "id": "copyright_owner",
                "dataVal": "Prasad",
                hasErrors: false,
                errorMessage: "JSON format incorrect!!!",
                editMode: "single",
                "required": false
            }, {
                "type": "json",
                "title": "Information",
                "id": "info",
                "dataVal": "Prasad",
                hasErrors: false,
                errorMessage: "JSON format incorrect!!!",
                editMode: "single",
                "required": false
            }, {
                "type": "boolean",
                "title": "Visible On Profile?",
                "id": "visible_on_profile",
                "dataVal": "Prasad",
                hasErrors: false,
                errorMessage: "Required field",
                editMode: "single",
                "required": true
            }, {
                "type": "json",
                "title": "Accessibility",
                "id": "accessibility",
                "dataVal": "Prasad",
                hasErrors: false,
                errorMessage: "JSON format incorrect!!!",
                editMode: "single",
                "required": false
            }, {
                "type": "json",
                "title": "Editorial Tags",
                "id": "editorial_tags",
                "dataVal": "Prasad",
                hasErrors: false,
                errorMessage: "JSON format incorrect!!!",
                editMode: "single",
                "required": false
            }, {
                "type": "integer",
                "title": "License",
                "id": "license",
                "dataVal": "Prasad",
                hasErrors: false,
                errorMessage: "Required field",
                editMode: "single",
                "required": false
            }, {
                "type": "string",
                "title": "Publish Status",
                "id": "publish_status",
                "dataVal": "Prasad",
                hasErrors: false,
                errorMessage: "Required field",
                editMode: "single",
                "required": false
            }, {
                "type": "json",
                "title": "Subject",
                "id": "subject",
                "dataVal": "Prasad",
                hasErrors: false,
                errorMessage: "Required field",
                editMode: "single",
                "required": false
            }, {
                "type": "boolean",
                "title": "Is Deleted?",
                "id": "is_delete",
                "dataVal": "Prasad",
                hasErrors: false,
                errorMessage: "Required field",
                editMode: "single",
                "required": true
            }]
        }
    };

    exports["default"] = _ember["default"].Component.extend({
        schema: master_schema,
        dataVal: {},
        editMode: 'single',
        didReceiveAttrs: function didReceiveAttrs() {
            this._super.apply(this, arguments);
            this.cleanInit();
            // this.cloneData();
        },

        cleanInit: function cleanInit() {
            console.log("cleanInit called!!!!!!!!!!!!!!!!!!!!!!!");
            var data = this.get('dataVal');
            var flds = this.get("schema").schema.fields;
            for (var i = 0; i < flds.length; i++) {
                flds[i].hasErrors = false;
                flds[i].dataVal = "";
                if (data[flds[i].id]) {
                    if (flds[i].type === "json") {
                        flds[i].dataVal = JSON.stringify(data[flds[i].id], undefined, 4);
                    } else {
                        flds[i].dataVal = data[flds[i].id];
                    }
                }
            };
        },
        validate: function validate() {
            var flds = this.get('schema').schema.fields;
            var edMode = this.get('editMode');
            var retVal = false;
            for (var i = 0; i < flds.length; i++) {
                if (edMode === flds[i].editMode && flds[i].type === "json") {
                    try {
                        JSON.parse(flds[i].dataVal);
                        _ember["default"].set(flds[i], 'hasErrors', false);
                    } catch (e) {
                        _ember["default"].set(flds[i], 'hasErrors', true);
                        retVal = true;
                    }
                }
                if (edMode === flds[i].editMode && (flds[i].dataVal === "" || flds[i].dataVal === null) && flds[i].required === false) {
                    _ember["default"].set(flds[i], 'hasErrors', false);
                }
            }
            return retVal;
        },
        actions: {
            gooruformChanged: function gooruformChanged() {
                console.log("On Action called ");
                this.validate();
            },

            saveForm: function saveForm() {
                if (this.validate()) return;
                var dataObj = {};
                var flds = this.get('schema').schema.fields;
                for (var i = 0; i < flds.length; i++) {
                    dataObj[flds[i].id] = flds[i].dataVal;
                }
                console.log("saveForm called " + JSON.stringify(dataObj));
                this.get('onSave')(dataObj);
            },
            cancelForm: function cancelForm() {
                console.log("cancelForm called");
                this.get('onCancel')();
            }
        }
    });
});