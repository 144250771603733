define('llama-table/components/llama-body-column/component', ['exports', 'ember', 'llama-table/components/llama-column/component', 'llama-table/components/llama-body-column/template'], function (exports, _ember, _llamaTableComponentsLlamaColumnComponent, _llamaTableComponentsLlamaBodyColumnTemplate) {
	'use strict';

	var _get = _ember['default'].get;
	var computed = _ember['default'].computed;

	var LlamaBodyColumn = _llamaTableComponentsLlamaColumnComponent['default'].extend({
		layout: _llamaTableComponentsLlamaBodyColumnTemplate['default'],
		classNames: 'llama-body-column',

		rows: null,
		column: null,

		itemViewClass: computed({
			get: function get() {
				var controller = this.get('root');
				var column = this.get('column');
				var type = _get(column, 'type');
				return controller.getCellType(type);
			}
		})
	});

	exports['default'] = LlamaBodyColumn;
});