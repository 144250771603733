define('llama-table/components/llama-loading/component', ['exports', 'ember', 'llama-table/components/llama-empty/component'], function (exports, _ember, _llamaTableComponentsLlamaEmptyComponent) {
	'use strict';

	var alias = _ember['default'].computed.alias;

	var LoadingView = _llamaTableComponentsLlamaEmptyComponent['default'].extend({
		classNames: ['llama-loading'],
		content: alias('root.loadingText')
	});

	exports['default'] = LoadingView;
});