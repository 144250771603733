define('adminui/services/impersonate', ['exports', 'ember', 'adminui/adapters/impersonate'], function (exports, _ember, _adminuiAdaptersImpersonate) {
	exports['default'] = _ember['default'].Service.extend({

		impersonateAdapter: null,

		init: function init() {
			this._super.apply(this, arguments);
			this.set('impersonateAdapter', _adminuiAdaptersImpersonate['default'].create(_ember['default'].getOwner(this).ownerInjection()));
		},

		doImpersonation: function doImpersonation(userId) {
			var service = this;
			return new _ember['default'].RSVP.Promise(function (resolve, reject) {
				service.get('impersonateAdapter').doImpersonation(userId).then(function (response) {
					resolve(response);
				}, reject);
			});
		}

	});
});