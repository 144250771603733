define('adminui/serializers/authentication', ['exports', 'ember'], function (exports, _ember) {
	exports['default'] = _ember['default'].Object.extend({
		/*
  	This is how session data will look like
  	{
  	  "email_id": "testuser@example.org",
  	  "country": null,
  	  "firstname": "Test",
  	  "metadata": null,
  	  "gender": null,
  	  "birth_date": null,
  	  "about_me": null,
  	  "thumbnail_path": null,
  	  "school_id": null,
  	  "school": null,
  	  "course": null,
  	  "state_id": null,
  	  "state": null,
  	  "cdn_urls": {
  	    "content_cdn_url": "//dev-content-example.org.s3-us-west-1.amazonaws.com/",
  	    "user_cdn_url": "//dev-user-example.org.s3-us-west-1.amazonaws.com/"
  	  },
  	  "school_district": null,
  	  "lastname": "User",
  	  "roster_id": null,
  	  "access_token": "MTQ2OTQzOTM2NTc0Mzo0MTY5MjJhYy03NDA0LTQwMGItODgzYS00OTkzZTIzZDFjOGY6YmE5NTZhOTctYWUxNS0xMWU1LWEzMDItZjhhOTYzMDY1OTc2",
  	  "user_category": null,
  	  "school_district_id": null,
  	  "roster_global_userid": null,
  	  "user_id": "8e263f96-22d7-4b7b-9f38-53334bda844d",
  	  "grade": null,
  	  "country_id": null,
  	  "username": "testuser"
  	}
  */

		normalizeResponse: function normalizeResponse(payload) {
			var userName = payload['username'] !== null ? payload['username'] : payload['email_id'];
			return {
				token: payload['access_token'],
				displayName: userName,
				sessionData: payload
			};
		}

	});
});