define('llama-table/mixins/focus-position', ['exports', 'ember'], function (exports, _ember) {
	'use strict';

	var deprecate = _ember['default'].deprecateFunc;
	var observer = _ember['default'].observer;
	var computed = _ember['default'].computed;
	var get = _ember['default'].get;

	/**
  * Responsible for observing and maintaining the position of the focused cell.
  * @module mixins
  * @constructor
  * @class FocusPositionMixin
  * @extends Ember.Mixin
  */
	var FocusPositionMixin = _ember['default'].Mixin.create({
		focusRow: null,
		focusColumn: null,
		focusCell: null,

		getRowIndex: function getRowIndex(row) {
			var sortedRows = this.get('sortedRows');
			var rowIndex = sortedRows.indexOf(row);
			return rowIndex;
		},

		getVisibleRowIndex: function getVisibleRowIndex(row) {
			return this.get('visibleRows').indexOf(row);
		},

		getVisibleRowAtIndex: function getVisibleRowAtIndex(rowIndex, wrap) {
			var visibleRows = this.get('visibleRows');

			if (wrap && rowIndex < 0) {
				rowIndex += visibleRows.length;
			}

			return visibleRows.objectAt(rowIndex);
		},

		getVisibleColumnIndex: function getVisibleColumnIndex(column) {
			var visibleColumns = _ember['default'].A(this.get('sortedColumns').rejectBy('isHidden'));
			var columnIndex = visibleColumns.indexOf(column);
			return columnIndex;
		},

		getVisibleColumnAtIndex: function getVisibleColumnAtIndex(columnIndex, wrap) {
			var visibleColumns = _ember['default'].A(this.get('sortedColumns').rejectBy('isHidden'));
			var column;
			if (wrap && columnIndex < 0) {
				columnIndex += visibleColumns.length;
			}
			column = visibleColumns.objectAt(columnIndex);
			return column;
		},

		getCellFor: deprecate('No longer able to search for cells', function () {}),

		focusView: deprecate('Assign `focusRow` and `focusColumn` instead', function () {}),

		setFocusCell: function setFocusCell(row, column) {
			this.setProperties({
				focusRow: row,
				focusColumn: column
			});
		},

		focusCurrentCell: deprecate('Cells are now focused automatically', function () {}),

		actions: {
			focusCell: function focusCell(row, column) {
				this.setFocusCell(row, column);
			},
			focusCurrentCell: function focusCurrentCell() {
				this.focusCurrentCell();
			},
			focusLeft: function focusLeft() {
				var column = this.get('focusColumn');
				var columnIndex = this.getVisibleColumnIndex(column);
				var wrapFocusHorizontal = this.get('wrapFocusHorizontal');
				var newColumn = this.getVisibleColumnAtIndex(columnIndex - 1, false);
				if (newColumn) {
					this.set('focusColumn', newColumn);
				} else if (wrapFocusHorizontal) {
					this.send('focusHardRight');
				} else {
					this.sendAction('outOfBounds', 'left', this);
				}
			},
			focusUp: function focusUp() {
				var row = this.get('focusRow');
				var rowIndex = this.getVisibleRowIndex(row);
				var wrapFocusVertical = this.get('wrapFocusVertical');
				var newRow = this.getVisibleRowAtIndex(rowIndex - 1, false);
				if (newRow) {
					this.set('focusRow', newRow);
				} else if (wrapFocusVertical) {
					this.send('focusHardDown');
				} else {
					this.sendAction('outOfBounds', 'up', this);
				}
			},
			focusRight: function focusRight() {
				var column = this.get('focusColumn');
				var columnIndex = this.getVisibleColumnIndex(column);
				var wrapFocusHorizontal = this.get('wrapFocusHorizontal');
				var newColumn = this.getVisibleColumnAtIndex(columnIndex + 1, false);
				if (newColumn) {
					this.set('focusColumn', newColumn);
				} else if (wrapFocusHorizontal) {
					this.send('focusHardLeft');
				} else {
					this.sendAction('outOfBounds', 'right', this);
				}
			},
			focusDown: function focusDown() {
				var row = this.get('focusRow');
				var rowIndex = this.getVisibleRowIndex(row);
				var wrapFocusVertical = this.get('wrapFocusVertical');
				var newRow = this.getVisibleRowAtIndex(rowIndex + 1, false);
				if (newRow) {
					this.set('focusRow', newRow);
				} else if (wrapFocusVertical) {
					this.send('focusHardUp');
				} else {
					this.sendAction('outOfBounds', 'down', this);
				}
			},
			focusHardLeft: function focusHardLeft() {
				var newColumn = this.getVisibleColumnAtIndex(0, true);
				if (newColumn) {
					this.set('focusColumn', newColumn);
				}
			},
			focusHardUp: function focusHardUp() {
				var newRow = this.getVisibleRowAtIndex(0, true);
				if (newRow) {
					this.set('focusRow', newRow);
				}
			},
			focusHardRight: function focusHardRight() {
				var newColumn = this.getVisibleColumnAtIndex(-1, true);
				if (newColumn) {
					this.set('focusColumn', newColumn);
				}
			},
			focusHardDown: function focusHardDown() {
				var newRow = this.getVisibleRowAtIndex(-1, true);
				if (newRow) {
					this.set('focusRow', newRow);
				}
			}
		}
	});

	exports['default'] = FocusPositionMixin;
});