define('adminui/services/signin', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Service.extend({

    session: _ember['default'].inject.service('session'),

    signIn: function signIn(username, password) {
      return this.get('session').authenticate('authenticator:admin-auth', {
        username: username,
        password: password
      });
    }

  });
});