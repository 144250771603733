define('llama-table/mixins/arrow-keys', ['exports', 'ember'], function (exports, _ember) {
	'use strict';

	var KEY_LEFT = 37;
	var KEY_UP = 38;
	var KEY_RIGHT = 39;
	var KEY_DOWN = 40;

	/**
  * Fires custom events on the implementor when arrow keys are pressed.
  * @module mixins
  * @constructor
  * @class ArrowKeysMixin
  * @extends Ember.Mixin
  */
	var ArrowKeysMixin = _ember['default'].Mixin.create({
		keyDown: function keyDown(e) {
			switch (e.which) {
				case KEY_LEFT:
					this.send('keyLeft', e);
					e.preventDefault();
					break;
				case KEY_UP:
					this.send('keyUp', e);
					e.preventDefault();
					break;
				case KEY_RIGHT:
					this.send('keyRight', e);
					e.preventDefault();
					break;
				case KEY_DOWN:
					this.send('keyDown', e);
					e.preventDefault();
					break;
			}
		}
	});

	exports['default'] = ArrowKeysMixin;
});