define('llama-table/components/llama-header-cell/component', ['exports', 'ember', 'llama-table/components/llama-cell/component', 'llama-table/computed', 'llama-table/components/llama-header-cell/template'], function (exports, _ember, _llamaTableComponentsLlamaCellComponent, _llamaTableComputed, _llamaTableComponentsLlamaHeaderCellTemplate) {
	'use strict';

	var computed = _ember['default'].computed;
	var alias = computed.alias;
	var bool = computed.bool;
	var and = computed.and;
	var not = computed.not;

	var LlamaHeaderCell = _llamaTableComponentsLlamaCellComponent['default'].extend({
		layout: _llamaTableComponentsLlamaHeaderCellTemplate['default'],
		classNames: 'llama-header-cell',
		classNameBindings: ['sortByThis', 'sortByThisAscending', 'sortByThisDescending', 'isSortable'],
		attributeBindings: ['title'],
		showLabel: bool('column.showLabel'),

		column: null,

		title: computed('column.label', {
			get: function get() {
				var label = this.get('column.label');
				if (_ember['default'].isBlank(label)) {
					label = this.get('column.name');
				}
				return label;
			}
		}),

		tableIsSortable: (0, _llamaTableComputed.defaultValue)('root.isSortable', true),
		columnIsSortable: (0, _llamaTableComputed.defaultValue)('column.isSortable', true),
		isSortable: and('tableIsSortable', 'columnIsSortable'),
		tableIsResizable: (0, _llamaTableComputed.defaultValue)('root.isResizable', true),
		columnIsResizable: (0, _llamaTableComputed.defaultValue)('column.isResizable', true),
		isResizable: and('tableIsResizable', 'columnIsResizable'),

		sortProperties: alias('root.sortProperties'),
		sortAscending: bool('root.sortAscending'),
		sortDescending: not('sortAscending'),

		sortByThis: computed('sortProperties.firstObject', 'column.sortBy', {
			get: function get() {
				var sortBy = this.get('sortProperties.firstObject');
				if (_ember['default'].isEmpty(sortBy)) {
					return false;
				}
				var sortedColumn = String(sortBy).split(':', 1)[0];
				var thisColumn = this.get('column.sortBy');
				return sortedColumn === thisColumn;
			}
		}),

		sortByThisAscending: and('sortByThis', 'sortAscending'),
		sortByThisDescending: and('sortByThis', 'sortDescending'),

		mouseDown: function mouseDown(e) {
			var isResizeAction = _ember['default'].$(e.target).is('.resize-handle');
			var controller = this.get('root');
			if (e.which === 1) {
				e.preventDefault();
				if (isResizeAction && this.get('isResizable')) {
					controller.send('startResize', e, this.get('column'));
				} else if (this.get('isSortable')) {
					controller.send('sortBy', this.get('column.sortBy'));
				}
			}
		}
	});

	exports['default'] = LlamaHeaderCell;
});