define('llama-table/components/llama-header-columngroup/component', ['exports', 'ember', 'llama-table/components/llama-columngroup/component', 'llama-table/components/llama-header-columngroup/template'], function (exports, _ember, _llamaTableComponentsLlamaColumngroupComponent, _llamaTableComponentsLlamaHeaderColumngroupTemplate) {
	'use strict';

	var alias = _ember['default'].computed.alias;

	var LlamaHeaderColumngroup = _llamaTableComponentsLlamaColumngroupComponent['default'].extend({
		layout: _llamaTableComponentsLlamaHeaderColumngroupTemplate['default'],
		classNames: 'llama-header-columngroup',
		itemViewClass: alias('root.HeaderColumnView'),

		columns: null
	});

	exports['default'] = LlamaHeaderColumngroup;
});