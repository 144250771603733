define('llama-table/mixins/view-constructors', ['exports', 'ember', 'llama-table/computed'], function (exports, _ember, _llamaTableComputed) {
	'use strict';

	/**
  * Defines each view used by the component. All views can be overridden.
  * @module mixins
  * @constructor
  * @class ViewConstructorsMixin
  * @extends Ember.Mixin
  */
	var ViewConstructorsMixin = _ember['default'].Mixin.create({
		TableView: (0, _llamaTableComputed.defaultValue)('config.TableView', 'llama-table-main'),
		HeaderView: (0, _llamaTableComputed.defaultValue)('config.HeaderView', 'llama-header'),
		HeaderColumngroupView: (0, _llamaTableComputed.defaultValue)('config.HeaderColumngroupView', 'llama-header-columngroup'),
		HeaderColumnView: (0, _llamaTableComputed.defaultValue)('config.HeaderColumnView', 'llama-header-column'),
		HeaderCellView: (0, _llamaTableComputed.defaultValue)('config.HeaderCellView', 'llama-header-cell'),
		ContentView: (0, _llamaTableComputed.defaultValue)('config.ContentView', 'llama-content'),
		SubcontentView: (0, _llamaTableComputed.defaultValue)('config.SubcontentView', 'llama-subcontent'),
		EmptyView: (0, _llamaTableComputed.defaultValue)('config.EmptyView', 'llama-empty'),
		LoadingView: (0, _llamaTableComputed.defaultValue)('config.LoadingView', 'llama-loading'),
		BodyView: (0, _llamaTableComputed.defaultValue)('config.BodyView', 'llama-body'),
		BodyColumngroupView: (0, _llamaTableComputed.defaultValue)('config.BodyColumngroupView', 'llama-body-columngroup'),
		BodyColumnView: (0, _llamaTableComputed.defaultValue)('config.BodyColumnView', 'llama-body-column'),
		BodyCellView: (0, _llamaTableComputed.defaultValue)('config.BodyCellView', 'llama-body-cell'),
		NumberCellView: (0, _llamaTableComputed.defaultValue)('config.NumberCellView', 'llama-number-cell'),
		EmbedView: (0, _llamaTableComputed.defaultValue)('config.EmbedView', 'llama-embed'),
		FooterView: (0, _llamaTableComputed.defaultValue)('config.FooterView', 'llama-footer'),
		FooterColumngroupView: (0, _llamaTableComputed.defaultValue)('config.FooterColumngroupView', 'llama-footer-columngroup'),
		FooterColumnView: (0, _llamaTableComputed.defaultValue)('config.FooterColumnView', 'llama-footer-column')
	});

	exports['default'] = ViewConstructorsMixin;
});