define("adminui/controllers/dashboard/impersonate", ["exports", "ember"], function (exports, _ember) {
	exports["default"] = _ember["default"].Controller.extend({

		searchCriteria: [{ value: "user_id", displayValue: "User Id" }, { value: "username", displayValue: "Username" }, { value: "email_id", displayValue: "Email Id" }],
		selectedCriteria: "user_id",
		searchValue: null,
		users: {},

		searchUsersService: _ember["default"].inject.service('userSearch'),
		impersonateService: _ember["default"].inject.service('impersonate'),

		actions: {
			searchUsers: function searchUsers() {
				var _this = this;

				var uuidFormat = /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;
				var searchValue = this.get('searchValue');
				var selectedCriteria = this.get('selectedCriteria');

				console.debug("Processing search users");
				if (searchValue === null || searchValue === undefined) {
					this.toast.warning("Invalid search value.");
				} else if (this.get('selectedCriteria') === "user_id" && searchValue.match(uuidFormat) === null) {
					this.toast.warning("User id should be uuid");
				} else {
					console.debug("Storing search criteria");
					console.debug(this.get('selectedCriteria'));
					console.debug(this.get('searchValue'));
					console.debug("Found search criteria, will process http call");
					this.get('searchUsersService').searchUsers(selectedCriteria, searchValue).then(function (response) {
						_this.set('users', response.users);
					}, function (error) {
						console.log("Http communication failed");
						console.log("Http status: " + error.status + " Body: [ " + error.responseText + "] ");
						_this.toast.error(error.responseJSON.message);
						_this.set('users', {});
					});
				}
			},

			impersonate: function impersonate(userId) {
				var _this2 = this;

				console.debug("Impersonate the user " + userId);
				var win = window.open('about:blank', '_blank');
				this.get('impersonateService').doImpersonation(userId).then(function (response) {
					var host = response["impersonate.target.uri"];
					var token = response["access_token"];
					var url = host + "/?access_token=" + token;
					console.debug("Trying to open new window with url: %s", url);
					win.location.href = url;
					_this2.toast.success("Impersonation successful");
				}, function (error) {
					console.log("Http communication failed");
					console.log("Http status: " + error.status + " Body: [ " + error.responseText + "] ");
					_this2.toast.error(error.responseJSON.message);
					win.close();
				});
			}
		}
	});
});