define('llama-table/mixins/sorted-rows', ['exports', 'ember', 'llama-table/controllers/row', 'llama-table/computed'], function (exports, _ember, _llamaTableControllersRow, _llamaTableComputed) {
	'use strict';

	var computed = _ember['default'].computed;
	var map = computed.map;
	var sort = computed.sort;
	var reads = computed.reads;

	var SortedRowsMixin = _ember['default'].Mixin.create({
		_rowsSource: (0, _llamaTableComputed.makeArray)('rows'),

		_rowsSortOrder: (0, _llamaTableComputed.makeArray)('sortProperties'),

		_rowsMapped: map('_rowsSource', function (model) {
			if (!(model instanceof _llamaTableControllersRow['default'])) {
				return _llamaTableControllersRow['default'].create({ model: model });
			}

			return model;
		}),

		_rowsSorted: sort('_rowsMapped', '_rowsSortOrder'),

		/**
   * Row values array with added sorting functionality. Uses a custom
   *   'RowsController' if table has subcontent. Does not construct this
   *   custom controller if it is not necessary.
   * @property {Ember.ArrayProxy} sortedRows
   */
		sortedRows: computed('_rowsMapped', '_rowsSortOrder', function () {
			if (_ember['default'].isEmpty(this.get('_rowsSortOrder'))) {
				return this.get('_rowsMapped');
			} else {
				return this.get('_rowsSorted');
			}
		})
	});

	exports['default'] = SortedRowsMixin;
});