define('llama-table/components/llama-cell/component', ['exports', 'ember'], function (exports, _ember) {
	'use strict';

	var computed = _ember['default'].computed;
	var alias = computed.alias;

	var LlamaCell = _ember['default'].Component.extend({
		classNames: 'llama-cell',

		column: null,
		row: null,

		columnView: alias('parentView'),
		config: alias('columnView.config')
	});

	exports['default'] = LlamaCell;
});