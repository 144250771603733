define('llama-table/mixins/scroll-xy', ['exports', 'ember', 'llama-table/mixins/scroll-handler'], function (exports, _ember, _llamaTableMixinsScrollHandler) {
	'use strict';

	var computed = _ember['default'].computed;
	var observer = _ember['default'].observer;

	/**
  * Fires 'scrollX' and 'scrollY' events when each axis is scrolled.
  * @module mixins
  * @constructor
  * @class ScrollXYMixin
  * @extends Ember.Mixin
  */
	var ScrollXYMixin = _ember['default'].Mixin.create(_llamaTableMixinsScrollHandler['default'], {

		_scrollLeft: computed({
			get: function get() {
				return _ember['default'].$(this.$()).scrollLeft();
			}
		}),

		_scrollTop: computed({
			get: function get() {
				return _ember['default'].$(this.$()).scrollTop();
			}
		}),

		_updateScrollLeftAndTop: observer(function () {
			this.get('_scrollLeft');
			this.get('_scrollTop');
		}),

		didInsertElement: function didInsertElement() {
			this._super();
			this._updateScrollLeftAndTop();
		},

		scroll: function scroll() {
			var $el = _ember['default'].$(this.$());
			var oldScrollLeft = this.get('_scrollLeft');
			var newScrollLeft = $el.scrollLeft();
			if (newScrollLeft !== oldScrollLeft) {
				this.set('_scrollLeft', newScrollLeft);
				this.send('scrollX', newScrollLeft);
			}
			var oldScrollTop = this.get('_scrollTop');
			var newScrollTop = $el.scrollTop();
			if (newScrollTop !== oldScrollTop) {
				this.set('_scrollTop', newScrollTop);
				this.send('scrollY', newScrollTop);
			}
		}
	});

	exports['default'] = ScrollXYMixin;
});