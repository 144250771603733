define('adminui/adapters/authentication', ['exports', 'ember', 'adminui/config/environment'], function (exports, _ember, _adminuiConfigEnvironment) {
	exports['default'] = _ember['default'].Object.extend({

		namespace: '/api/nucleus-admin/v1/authentication',

		postAuthentication: function postAuthentication(data) {
			var adapter = this;
			var namespace = this.get('namespace');
			var options = {
				type: 'POST',
				contentType: 'application/json; charset=utf-8',
				dataType: 'json',
				processData: false,
				headers: adapter.defineHeaders(data),
				data: _adminuiConfigEnvironment['default']['authenticationData'],
				global: false /* Stop global ajaxError event from triggering */
			};
			return _ember['default'].$.ajax(namespace, options);
		},

		defineHeaders: function defineHeaders(data) {
			return {
				'Authorization': 'Basic ' + btoa(data.username + ':' + data.password)
			};
		}

	});
});