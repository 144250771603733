define('adminui/router', ['exports', 'ember', 'adminui/config/environment'], function (exports, _ember, _adminuiConfigEnvironment) {

  var Router = _ember['default'].Router.extend({
    location: _adminuiConfigEnvironment['default'].locationType,
    rootURL: _adminuiConfigEnvironment['default'].rootURL
  });

  Router.map(function () {
    this.route('login');
    this.route('dashboard', function () {
      this.route('impersonate');
      this.route('searchcontent');
      this.route('editcontent');
      this.route('bulkeditcontent');
    });
  });

  exports['default'] = Router;
});