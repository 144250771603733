define('llama-table/components/llama-header-column/component', ['exports', 'ember', 'llama-table/components/llama-column/component', 'llama-table/components/llama-header-column/template'], function (exports, _ember, _llamaTableComponentsLlamaColumnComponent, _llamaTableComponentsLlamaHeaderColumnTemplate) {
	'use strict';

	var computed = _ember['default'].computed;

	var LlamaHeaderColumn = _llamaTableComponentsLlamaColumnComponent['default'].extend({
		layout: _llamaTableComponentsLlamaHeaderColumnTemplate['default'],
		classNames: 'llama-header-column',

		column: null,

		itemViewClass: computed('config.header', {
			get: function get() {
				var ViewClass = this.get('config.header');
				if (_ember['default'].isEmpty(ViewClass)) {
					ViewClass = this.get('root.HeaderCellView');
				}
				return ViewClass;
			}
		})
	});

	exports['default'] = LlamaHeaderColumn;
});