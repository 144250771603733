define('llama-table/components/llama-columngroup/component', ['exports', 'ember', 'llama-table/components/llama-columngroup/template'], function (exports, _ember, _llamaTableComponentsLlamaColumngroupTemplate) {
	'use strict';

	var on = _ember['default'].on;
	var observer = _ember['default'].observer;
	var computed = _ember['default'].computed;
	var setDiff = computed.setDiff;
	var filterBy = computed.filterBy;
	var mapBy = computed.mapBy;
	var sum = computed.sum;

	var LlamaColumngroup = _ember['default'].Component.extend({
		layout: _llamaTableComponentsLlamaColumngroupTemplate['default'],
		classNames: 'llama-columngroup',
		columnViews: filterBy('childViews', 'isVisible', true),
		contentBinding: 'columns',

		columns: null,

		hiddenColumns: filterBy('columns', 'isHidden'),
		visibleColumns: setDiff('columns', 'hiddenColumns'),
		visibleColumnWidths: mapBy('visibleColumns', 'width'),
		width: sum('visibleColumnWidths'),

		setWidth: on('didInsertElement', observer('width', function () {
			var width = this.get('width');
			var $columngroup = _ember['default'].$(this.$());
			$columngroup.width(width);
		}))
	});

	exports['default'] = LlamaColumngroup;
});