define('adminui/adapters/search-user', ['exports', 'ember'], function (exports, _ember) {
	function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

	exports['default'] = _ember['default'].Object.extend({

		session: _ember['default'].inject.service(),

		namespace: '/api/nucleus-admin/v1/auth/user/search',

		getMatchingUsers: function getMatchingUsers(searchKey, searchValue) {
			var adapter = this;
			var namespace = this.get('namespace');
			var options = {
				type: 'GET',
				contentType: 'application/json; charset=utf-8',
				dataType: 'json',
				processData: true,
				headers: adapter.defineHeaders(),
				data: _defineProperty({}, searchKey, searchValue),
				global: false /* Stop global ajaxError event from triggering */
			};
			return _ember['default'].$.ajax(namespace, options);
		},

		defineHeaders: function defineHeaders() {
			var sessionData = this.get('session').get('data');
			var token = sessionData.authenticated.token;
			console.log('token is ' + token);
			return {
				'Authorization': 'Token ' + token
			};
		}

	});
});