define("adminui/routes/dashboard/searchcontent", ["exports", "ember"], function (exports, _ember) {
		exports["default"] = _ember["default"].Route.extend({
				model: function model() {
						return {
								"id": "uuid",
								"title": "string",
								"url": "string",
								"is_remote": "boolean",
								"is_broken": "boolean",
								"is_iframe_breaker": "boolean",
								"iframe_breaker_reason": "enum",
								"created_at": "date",
								"updated_at": "date",
								"creator_id": "uuid",
								"modifier_id": "uuid",
								"publish_date": "date",
								"publish_status": "enum",
								"subject": "json",
								"language": "string",
								"narration": "string",
								"description": "string",
								"content_subformat": "enum",
								"audience": "array",
								"educational_use": "array",
								"metadata": "json",
								"taxonomy": "json",
								"gut_codes": "json",
								"thumbnail": "string",
								"is_copyright_owner": "boolean",
								"copyright_owner": "json",
								"info": "json",
								"visible_on_profile": "boolean",
								"display_guide": "json",
								"accessibility": "json",
								"is_deleted": "boolean",
								"editorial_tags": "json",
								"license": "integer",
								"creator_system": "string"
						};
				},

				setupController: function setupController(controller, model) {
						controller.set('content', model);
				}
		});
});