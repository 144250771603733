define('llama-table/components/llama-subcontent/component', ['exports', 'ember', 'llama-table/components/llama-subcontent/template'], function (exports, _ember, _llamaTableComponentsLlamaSubcontentTemplate) {
	'use strict';

	var computed = _ember['default'].computed;
	var alias = computed.alias;
	var filterBy = computed.filterBy;

	var LlamaSubcontent = _ember['default'].Component.extend({
		layout: _llamaTableComponentsLlamaSubcontentTemplate['default'],
		classNames: ['llama-subcontent'],
		contentBinding: 'expandedRows',

		rows: null,

		itemViewClass: alias('root.EmbedView'),
		expandedRows: filterBy('rows', 'isExpanded')
	});

	exports['default'] = LlamaSubcontent;
});