define('llama-table/mixins/resize-columns', ['exports', 'ember', 'llama-table/controllers/resize'], function (exports, _ember, _llamaTableControllersResize) {
	'use strict';

	var get = _ember['default'].get;
	var set = _ember['default'].set;
	var isEmpty = _ember['default'].isEmpty;
	var computed = _ember['default'].computed;
	var observer = _ember['default'].observer;

	/**
  * Utilizes the ResizeController to enable resizing of columns.
  * @module mixins
  * @constructor
  * @class ResizeColumnsMixin
  * @extends Ember.Mixin
  */
	var ResizeColumns = _ember['default'].Mixin.create({
		resizeColumn: null,
		resizeBeginWidth: null,

		resizeController: computed({
			get: function get() {
				return _llamaTableControllersResize['default'].create();
			}
		}),

		willDestroy: function willDestroy() {
			this.get('resizeController').destroy();
			this._super();
		},

		updateResize: observer('resizeController.deltaX', function () {
			var beginWidth = this.get('resizeBeginWidth');
			var column = this.get('resizeColumn');
			var maxWidth = get(column, 'maxWidth');
			var minWidth = get(column, 'minWidth');
			var deltaX = this.get('resizeController.deltaX');
			var newWidth = beginWidth + deltaX;
			if (!isEmpty(minWidth)) {
				newWidth = Math.max(newWidth, minWidth);
			}
			if (!isEmpty(maxWidth)) {
				newWidth = Math.min(newWidth, maxWidth);
			}
			set(column, 'width', newWidth);
		}),

		actions: {
			startResize: function startResize(e, column) {
				this.set('resizeColumn', column);
				this.set('resizeBeginWidth', get(column, 'width'));
				this.get('resizeController').send('startResize', e);
			}
		}
	});

	exports['default'] = ResizeColumns;
});