define('adminui/services/authentication', ['exports', 'ember', 'adminui/serializers/authentication', 'adminui/adapters/authentication'], function (exports, _ember, _adminuiSerializersAuthentication, _adminuiAdaptersAuthentication) {
	exports['default'] = _ember['default'].Service.extend({

		authenticationSerializer: null,

		authenticationAdapter: null,

		init: function init() {
			this._super.apply(this, arguments);
			this.set('authenticationSerializer', _adminuiSerializersAuthentication['default'].create(_ember['default'].getOwner(this).ownerInjection()));
			this.set('authenticationAdapter', _adminuiAdaptersAuthentication['default'].create(_ember['default'].getOwner(this).ownerInjection()));
		},

		authenticateWithCredentials: function authenticateWithCredentials(username, password) {
			var service = this;
			return new _ember['default'].RSVP.Promise(function (resolve, reject) {
				service.get('authenticationAdapter').postAuthentication({
					username: username,
					password: password
				}).then(function (response) {
					resolve(service.get('authenticationSerializer').normalizeResponse(response));
				}, reject);
			});
		}
	});
});