define('llama-table/components/llama-content/component', ['exports', 'ember', 'llama-table/mixins/arrow-keys', 'llama-table/controllers/copy', 'llama-table/components/llama-content/template'], function (exports, _ember, _llamaTableMixinsArrowKeys, _llamaTableControllersCopy, _llamaTableComponentsLlamaContentTemplate) {
	'use strict';

	var computed = _ember['default'].computed;
	var alias = computed.alias;
	var filterBy = computed.filterBy;
	var TAB = 9;
	var ENTER = 13;

	var LlamaContent = _ember['default'].Component.extend(_llamaTableMixinsArrowKeys['default'], {
		layout: _llamaTableComponentsLlamaContentTemplate['default'],
		classNames: 'llama-content',
		attributeBindings: ['tabindex'],
		tabindex: 0,
		itemViewClass: alias('root.BodyColumngroupView'),
		columngroupViews: filterBy('childViews', 'isVisible', true),

		rows: null,
		columngroups: null,
		contentBinding: 'columngroups',

		copyController: _llamaTableControllersCopy['default'].create(),

		handleTabKey: function handleTabKey(e) {
			e.preventDefault();
			if (e.shiftKey) {
				this.get('root').send('reverseTabKey');
			} else {
				this.get('root').send('tabKey');
			}
		},

		handleEnterKey: function handleEnterKey(e) {
			e.preventDefault();
			if (e.shiftKey) {
				this.get('root').send('reverseEnterKey');
			} else {
				this.get('root').send('enterKey');
			}
		},

		keyDown: function keyDown(e) {
			var controller = this.get('copyController');
			var isCopy = controller.isShortcutCopy(e);
			var isRange = controller.isSelectionRange();
			if (isCopy && !isRange) {
				controller.copy(e.target);
			} else if (e.which === TAB) {
				this.handleTabKey(e);
			} else if (e.which === ENTER) {
				this.handleEnterKey(e);
			} else {
				this._super(e);
			}
		},

		actions: {
			keyLeft: function keyLeft(e) {
				var controller = this.get('root');
				if (e.shiftKey) {
					controller.send('focusHardLeft');
				} else {
					controller.send('focusLeft');
				}
			},
			keyUp: function keyUp(e) {
				var controller = this.get('root');
				if (e.shiftKey) {
					controller.send('focusHardUp');
				} else {
					controller.send('focusUp');
				}
			},
			keyRight: function keyRight(e) {
				var controller = this.get('root');
				if (e.shiftKey) {
					controller.send('focusHardRight');
				} else {
					controller.send('focusRight');
				}
			},
			keyDown: function keyDown(e) {
				var controller = this.get('root');
				if (e.shiftKey) {
					controller.send('focusHardDown');
				} else {
					controller.send('focusDown');
				}
			}
		}
	});

	exports['default'] = LlamaContent;
});