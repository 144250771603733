define('llama-table/components/llama-body-columngroup/component', ['exports', 'ember', 'llama-table/components/llama-columngroup/component', 'llama-table/components/llama-body-columngroup/template'], function (exports, _ember, _llamaTableComponentsLlamaColumngroupComponent, _llamaTableComponentsLlamaBodyColumngroupTemplate) {
	'use strict';

	var LlamaBodyColumngroup = _llamaTableComponentsLlamaColumngroupComponent['default'].extend({
		layout: _llamaTableComponentsLlamaBodyColumngroupTemplate['default'],
		classNames: 'llama-body-columngroup',
		itemViewClass: _ember['default'].computed.alias('root.BodyColumnView'),

		columns: null,
		rows: null
	});

	exports['default'] = LlamaBodyColumngroup;
});