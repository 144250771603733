define('adminui/controllers/dashboard/index', ['exports', 'ember'], function (exports, _ember) {
	exports['default'] = _ember['default'].Controller.extend({

		actions: {
			impersonate: function impersonate() {
				this.transitionToRoute('dashboard.impersonate');
			},
			searchContent: function searchContent() {
				console.log("Inside searchContent");
				this.transitionToRoute('dashboard.searchcontent');
			}
		}
	});
});