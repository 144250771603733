define('llama-table/components/llama-number-cell/component', ['exports', 'ember', 'llama-table/components/llama-body-cell/component'], function (exports, _ember, _llamaTableComponentsLlamaBodyCellComponent) {
	'use strict';

	var computed = _ember['default'].computed;

	var LlamaNumberCell = _llamaTableComponentsLlamaBodyCellComponent['default'].extend({
		classNames: 'number',
		formatted: computed('value', {
			get: function get() {
				var value = this.get('value');
				var number = Number(value);
				return number;
			}
		})
	});

	exports['default'] = LlamaNumberCell;
});