define('llama-table/components/llama-empty/component', ['exports', 'ember', 'llama-table/components/llama-empty/template'], function (exports, _ember, _llamaTableComponentsLlamaEmptyTemplate) {
	'use strict';

	var alias = _ember['default'].computed.alias;

	var EmptyView = _ember['default'].Component.extend({
		layout: _llamaTableComponentsLlamaEmptyTemplate['default'],
		classNames: ['llama-empty'],
		content: alias('root.emptyText')
	});

	exports['default'] = EmptyView;
});