define('adminui/services/editcontent', ['exports', 'ember', 'adminui/adapters/editcontent'], function (exports, _ember, _adminuiAdaptersEditcontent) {
	exports['default'] = _ember['default'].Service.extend({

		editContentAdapter: null,

		init: function init() {
			this._super.apply(this, arguments);
			this.set('editContentAdapter', _adminuiAdaptersEditcontent['default'].create(_ember['default'].getOwner(this).ownerInjection()));
		},

		saveContent: function saveContent(id, contentItem) {
			var service = this;
			return new _ember['default'].RSVP.Promise(function (resolve, reject) {
				service.get('editContentAdapter').saveContent(id, contentItem).then(function (response) {
					resolve(response);
				}, reject);
			});
		}
	});
});