define('adminui/services/searchcontent', ['exports', 'ember', 'adminui/adapters/searchcontent'], function (exports, _ember, _adminuiAdaptersSearchcontent) {
	exports['default'] = _ember['default'].Service.extend({

		searchContentAdapter: null,

		init: function init() {
			this._super.apply(this, arguments);
			this.set('searchContentAdapter', _adminuiAdaptersSearchcontent['default'].create(_ember['default'].getOwner(this).ownerInjection()));
		},

		searchContent: function searchContent(selectedCriteria, searchValue, filterKey, filterValue) {
			var service = this;
			return new _ember['default'].RSVP.Promise(function (resolve, reject) {
				service.get('searchContentAdapter').getSearchContent(selectedCriteria, searchValue, filterKey, filterValue).then(function (response) {
					resolve(response);
				}, reject);
			});
		}
	});
});