define('adminui/routes/application', ['exports', 'ember'], function (exports, _ember) {
	exports['default'] = _ember['default'].Route.extend({

		session: _ember['default'].inject.service(),

		actions: {

			logout: function logout() {
				this.get('session').invalidate();
				this.transitionTo('login');
			},

			login: function login() {
				this.transitionTo('dashboard');
			}
		}

	});
});