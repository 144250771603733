define('llama-table/components/llama-header/component', ['exports', 'ember', 'llama-table/components/llama-header/template'], function (exports, _ember, _llamaTableComponentsLlamaHeaderTemplate) {
	'use strict';

	var observer = _ember['default'].observer;
	var alias = _ember['default'].computed.alias;
	var filterBy = _ember['default'].computed.filterBy;

	var LlamaHeader = _ember['default'].Component.extend({
		layout: _llamaTableComponentsLlamaHeaderTemplate['default'],
		classNames: 'llama-header',
		itemViewClass: alias('root.HeaderColumngroupView'),
		columngroupViews: filterBy('childViews', 'isVisible', true),
		contentBinding: 'columngroups',
		scrollTop: alias('root.scrollTop'),

		columngroups: null,

		didInsertElement: function didInsertElement() {
			this._super();
			this.updateScrollPosition();
		},

		updateScrollPosition: observer('scrollTop', function () {
			var $header = _ember['default'].$(this.$());
			$header.css('marginTop', this.get('scrollTop'));
		})
	});

	exports['default'] = LlamaHeader;
});