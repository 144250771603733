define('llama-table/components/llama-body-cell/component', ['exports', 'ember', 'llama-table/components/llama-cell/component', 'llama-table/computed', 'llama-table/components/llama-body-cell/template'], function (exports, _ember, _llamaTableComponentsLlamaCellComponent, _llamaTableComputed, _llamaTableComponentsLlamaBodyCellTemplate) {
	'use strict';

	var defineProperty = _ember['default'].defineProperty;
	var deprecate = _ember['default'].deprecateFunc;
	var observer = _ember['default'].observer;
	var computed = _ember['default'].computed;
	var alias = computed.alias;
	var or = computed.or;
	var and = computed.and;
	var bool = computed.bool;
	var empty = computed.empty;
	var not = computed.not;
	var ESC = 27;
	var SPACE = 32;

	var LlamaBodyCell = _llamaTableComponentsLlamaCellComponent['default'].extend({
		layout: _llamaTableComponentsLlamaBodyCellTemplate['default'],
		classNames: 'llama-body-cell',
		classNameBindings: ['hover', 'columnIsClickable', 'rowIsClickable', 'isClickable', 'isEmpty', 'showingSubcontent', 'isEditable'],
		attributeBindings: ['tabindex'],
		height: alias('row.height'),
		columnIsClickable: alias('column.isClickable'),
		rowIsBody: not('isFooter'),
		rowIsClickable: and('root.enableRowClick', 'rowIsBody'),
		isClickable: or('columnIsClickable', 'rowIsClickable'),
		showingSubcontent: bool('row.isExpanded'),
		isEmpty: empty('value'),
		isEditable: bool('column.isEditable'),
		isFooter: false,

		isFocusColumn: (0, _llamaTableComputed.eq)('root.focusColumn', 'column'),
		isFocusRow: (0, _llamaTableComputed.eq)('root.focusRow', 'row'),
		isFocusCell: and('isFocusColumn', 'isFocusRow'),

		focusObserver: observer('isFocusCell', function () {
			if (this.get('isFocusCell')) {
				var $cell = _ember['default'].$(this.$());
				$cell.focus();
				this.set('root.focusCell', this);
			}
		}),

		column: null,
		row: null,

		cell: null,
		value: alias('cell'),
		formatted: alias('value'),

		hover: computed('root.hoverRow', 'row', 'isFooter', {
			get: function get() {
				return !this.get('isFooter') && this.get('root.hoverRow') === this.get('row');
			}
		}),

		tabindex: computed('isEditable', {
			get: function get() {
				var onlyFocusEditable = this.get('root.onlyFocusEditable');
				var isEditable = this.get('isEditable');
				var index = onlyFocusEditable && !isEditable ? null : 0;
				return index;
			}
		}),

		// only calculated once
		observedFields: computed({
			get: function get() {
				var observes = this.get('column.observes');
				if (!_ember['default'].isEmpty(observes)) {
					return observes;
				}
				var id = this.get('column.name');
				return id;
			}
		}),

		marginBottom: computed('showingSubcontent', 'row.subcontentHeight', {
			get: function get() {
				var isExpanded = this.get('showingSubcontent');
				if (isExpanded) {
					return this.get('row.subcontentHeight');
				}
				return null;
			}
		}),

		init: function init() {
			this._super();
			this.buildComputedValue();
			this.focusObserver();
		},

		didInsertElement: function didInsertElement() {
			this._super();
			this.setHeight();
			this.setMarginBottom();
		},

		setHeight: observer('height', function () {
			var $cell = _ember['default'].$(this.$());
			$cell.css('height', this.get('height'));
		}),

		setMarginBottom: observer('marginBottom', function () {
			var $cell = _ember['default'].$(this.$());
			$cell.css('marginBottom', this.get('marginBottom') || 0);
		}),

		getValue: deprecate('Override `value` property instead', function () {}),

		updateValue: deprecate('Override `value` property instead', function () {}),

		buildComputedValue: observer('column.name', function () {
			var columnName = this.get('column.name');
			var prop = alias('row.' + columnName);
			defineProperty(this, 'cell', prop);
		}),

		mouseEnter: function mouseEnter() {
			var row = this.get('row');
			this.get('root').send('highlightRow', row);
		},

		mouseLeave: function mouseLeave() {
			this.get('root').send('stopHighlightingRows');
		},

		click: function click() {
			var controller = this.get('root');
			var row = this.get('row');
			var column = this.get('column');
			if (this.get('isClickable')) {
				controller.sendAction('cellClick', row, column);
			}
			if (controller.get('enableRowClick')) {
				controller.sendAction('rowClick', row);
			}
		},

		focusIn: function focusIn() {
			var controller = this.get('root');
			var row = this.get('row');
			var column = this.get('column');
			controller.send('focusCell', row, column);
			return false;
		},

		keyDown: function keyDown(e) {
			if (e.which === ESC) {
				this.$().blur();
			} else if (e.which === SPACE) {
				this.send('primaryAction', e);
			} else {
				this._super(e);
			}
		},

		actions: {
			primaryAction: function primaryAction(e) {
				// cancel action by default
				e.preventDefault();
			}
		}
	});

	exports['default'] = LlamaBodyCell;
});