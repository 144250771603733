define('adminui/initializers/row-controller', ['exports', 'llama-table/controllers/row'], function (exports, _llamaTableControllersRow) {

	var initializer = {
		name: 'llama-row',
		initialize: function initialize(container) {
			container.register('controller:llama-row', _llamaTableControllersRow['default']);
		}
	};

	exports['default'] = initializer;
});