define('llama-table/components/llama-body/component', ['exports', 'ember', 'llama-table/components/llama-body/template'], function (exports, _ember, _llamaTableComponentsLlamaBodyTemplate) {
	'use strict';

	var computed = _ember['default'].computed;
	var bool = computed.bool;
	var not = computed.not;
	var and = computed.and;

	var LlamaBody = _ember['default'].Component.extend({
		layout: _llamaTableComponentsLlamaBodyTemplate['default'],
		classNames: ['llama-body'],
		isEmpty: bool('root.datasetIsEmpty'),
		isLoading: bool('root.isLoading'),
		notLoading: not('isLoading'),
		hasSubcontent: bool('root.hasSubcontent'),

		columngroups: null,
		rows: null,

		renderContent: true,
		renderSubcontent: bool('hasSubcontent'),
		renderEmpty: and('isEmpty', 'notLoading'),
		renderLoading: and('isEmpty', 'isLoading')
	});

	exports['default'] = LlamaBody;
});