define('adminui/app', ['exports', 'ember', 'adminui/resolver', 'ember-load-initializers', 'adminui/config/environment'], function (exports, _ember, _adminuiResolver, _emberLoadInitializers, _adminuiConfigEnvironment) {

  var App = undefined;

  _ember['default'].MODEL_FACTORY_INJECTIONS = true;

  App = _ember['default'].Application.extend({
    modulePrefix: _adminuiConfigEnvironment['default'].modulePrefix,
    podModulePrefix: _adminuiConfigEnvironment['default'].podModulePrefix,
    Resolver: _adminuiResolver['default']
  });

  (0, _emberLoadInitializers['default'])(App, _adminuiConfigEnvironment['default'].modulePrefix);

  exports['default'] = App;
});