define('adminui/controllers/dashboard/editcontent', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Controller.extend({

    session: _ember['default'].inject.service(),
    editContentService: _ember['default'].inject.service('editcontent'),

    cont: {},
    metadata: null,
    showMetadata: false,
    showTaxonomy: false,
    showInfo: false,
    showAccess: false,
    showTags: false,
    data: null,
    saveDisable: false,
    jsonFormat: function jsonFormat() {
      var obj = this.get('cont');
      this.set('metadata', JSON.stringify(obj.metadata, undefined, 4));
      this.set('taxonomy', JSON.stringify(obj.taxonomy, undefined, 4));
      this.set('info', JSON.stringify(obj.info, undefined, 4));
      this.set('accessibility', JSON.stringify(obj.accessibility, undefined, 4));
      this.set('tags', JSON.stringify(obj.editorial_tags, undefined, 4));
    },
    init: function init() {
      this.cont = this.get('session').get('contentItem');
      this.jsonFormat();
    },
    getPayload: function getPayload() {
      return {
        "title": this.cont.title,
        "url": this.cont.url,

        "copyright_owner": this.cont.copyright_owner,
        "narration": this.cont.narration,
        "description": this.cont.description,
        "metadata": JSON.parse(this.metadata),
        "taxonomy": JSON.parse(this.taxonomy),
        "info": JSON.parse(this.info),
        "accessibility": JSON.parse(this.accessibility),
        "editorial_tags": JSON.parse(this.tags),
        "content_subformat": this.cont.content_subformat,

        "is_copyright_owner": this.cont.is_copyright_owner,
        "visible_on_profile": this.cont.visible_on_profile,
        "license": this.cont.licenses

      };
    },
    actions: {

      cancelAction: function cancelAction() {
        this.transitionToRoute('dashboard.searchcontent');
      },
      navigateDashboard: function navigateDashboard() {
        this.transitionToRoute('dashboard');
      },
      setdata: function setdata(value) {
        this.set('data', value);
      },
      validate: function validate(value) {
        var data = this.get('data');
        try {
          JSON.parse(value);
          if (data == 'metadata') {
            this.set('showMetadata', false);
          }
          if (data == 'taxonomy') {
            this.set('showTaxonomy', false);
          }
          if (data == 'info') {
            this.set('showInfo', false);
          }
          if (data == 'accessibility') {
            this.set('showAccess', false);
          }
          if (data == 'tags') {
            this.set('showTags', false);
          }
          if (this.get('showMetadata') == false && this.get('showTaxonomy') == false && this.get('showInfo') == false && this.get('showAccess') == false && this.get('showTags') == false) {
            this.set('saveDisable', false);
          }
        } catch (e) {
          if (data == 'metadata') {
            this.set('showMetadata', true);
          }
          if (data == 'taxonomy') {
            this.set('showTaxonomy', true);
          }
          if (data == 'info') {
            this.set('showInfo', true);
          }
          if (data == 'accessibility') {
            this.set('showAccess', true);
          }
          if (data == 'tags') {
            this.set('showTags', true);
          }
          if (this.get('showMetadata') == true || this.get('showTaxonomy') == true || this.get('showInfo') == true || this.get('showAccess') == true || this.get('showTags') == true) {
            this.set('saveDisable', true);
          }
        }
      },

      submitAction: function submitAction(payload) {
        var _this = this;

        // console.log("Save edit "+JSON.stringify(this.cont));
        // var payload = this.getPayload();
        console.log("Save edit payload " + JSON.stringify(payload));

        this.get('editContentService').saveContent(this.cont.id, payload).then(function (response) {
          var res = JSON.stringify(response.resources);
          _this.toast.info("Data successfully saved!!!");
        }, function (error) {
          console.log("Http communication failed");
          console.log('Http status: ' + error.status + ' Body: [ ' + error.responseText + '] ');
          _this.toast.error("Oops!! Saving your changes failed. Please try again.");
        });
      }
    }
    //metadata:JSON.stringify('cont', undefined, 4),

  });
});