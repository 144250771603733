define('adminui/controllers/login', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Controller.extend({

    signin: _ember['default'].inject.service(),

    actions: {
      authenticate: function authenticate() {
        var _this = this;

        var username = this.get('username'),
            password = this.get('password');
        this.get("signin").signIn(username, password).then(function () {
          console.log('Sign in successful');
          _this.send('login');
          _this.set('password', "");
        }, function (error) {
          if (error.responseJSON !== undefined && error.responseJSON.message !== undefined) {
            _this.toast.error(error.responseJSON.message);
          } else {
            _this.toast.error("Internal error. Not able to login.");
            console.error('Status ' + error.status + ' Response: ' + error.responseText);
          }
        });
      }
    }
  });
});