define('llama-table/controllers/row', ['exports', 'ember', 'llama-table/computed'], function (exports, _ember, _llamaTableComputed) {
	'use strict';

	var makeArray = _ember['default'].makeArray;
	var computed = _ember['default'].computed;
	var alias = computed.alias;
	var reads = computed.reads;

	/**
  * Adds some extra properties to row definitions.
  * @module controllers
  * @constructor
  * @class RowController
  * @extends Ember.ObjectProxy
  */
	var RowController = _ember['default'].ObjectProxy.extend({
		content: alias('model'),
		contentIndex: computed('parentController.[]', {
			get: function get() {
				var array = this.get('parentController');
				var self = this;
				var content = self;
				var index = makeArray(array).indexOf(content);
				return index;
			}
		}),
		isExpanded: false,
		height: (0, _llamaTableComputed.defaultValue)('content.height', 30),
		subcontentHeight: reads('height')
	});

	exports['default'] = RowController;
});