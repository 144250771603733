define('llama-table/mixins/sorted-columns', ['exports', 'ember', 'llama-table/controllers/column', 'llama-table/computed'], function (exports, _ember, _llamaTableControllersColumn, _llamaTableComputed) {
	'use strict';

	var computed = _ember['default'].computed;
	var map = computed.map;
	var sort = computed.sort;
	var reads = computed.reads;

	var SortedColumnsMixin = _ember['default'].Mixin.create({
		_columnsSource: (0, _llamaTableComputed.makeArray)('columns'),

		_columnsSortOrder: ['order'],

		_columnsMapped: map('_columnsSource', function (model) {
			return _llamaTableControllersColumn['default'].create({ model: model });
		}),

		_columnsSorted: sort('_columnsMapped', '_columnsSortOrder'),

		/**
   * Column definitions array with added sorting functionality.
   * @property {Ember.ArrayProxy} sortedColumns
   */
		sortedColumns: reads('_columnsSorted')
	});

	exports['default'] = SortedColumnsMixin;
});