define('llama-table/components/llama-table-main/component', ['exports', 'ember', 'llama-table/mixins/scroll-xy', 'llama-table/components/llama-table-main/template'], function (exports, _ember, _llamaTableMixinsScrollXy, _llamaTableComponentsLlamaTableMainTemplate) {
	'use strict';

	var observer = _ember['default'].observer;
	var computed = _ember['default'].computed;
	var alias = computed.alias;
	var bool = computed.bool;

	var LlamaTable = _ember['default'].Component.extend(_llamaTableMixinsScrollXy['default'], {
		layout: _llamaTableComponentsLlamaTableMainTemplate['default'],
		classNames: 'llama-table',
		dualHeaders: bool('root.dualHeaders'),
		showFooter: bool('root.showFooter'),
		scrollLeft: alias('root.scrollLeft'),
		scrollTop: alias('root.scrollTop'),

		rows: null,
		visibleRows: null,
		columngroups: null,

		didInsertElement: function didInsertElement() {
			this._super();
			this.setHeight();
			this.updateScrollPosition();
		},

		updateScrollPosition: observer('scrollLeft', 'scrollTop', function () {
			var $table = _ember['default'].$(this.$());
			$table.scrollLeft(this.get('scrollLeft'));
			$table.scrollTop(this.get('scrollTop'));
		}),

		setHeight: observer('root.maxHeight', function () {
			var $table = _ember['default'].$(this.$());
			$table.css('maxHeight', this.get('root.maxHeight'));
		}),

		actions: {
			scrollX: function scrollX(pos) {
				this.set('scrollLeft', pos);
			},
			scrollY: function scrollY(pos) {
				this.set('scrollTop', pos);
			}
		}
	});

	exports['default'] = LlamaTable;
});