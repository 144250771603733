define('adminui/adapters/editcontent', ['exports', 'ember'], function (exports, _ember) {
	exports['default'] = _ember['default'].Object.extend({

		session: _ember['default'].inject.service(),

		namespace: '/api/nucleus-admin/v1/auth/content/update/',

		saveContent: function saveContent(id, contentItem) {
			console.log('saveContent: : : ' + JSON.stringify(contentItem));
			var adapter = this;
			var namespace = this.get('namespace') + id;
			var options = {
				type: 'PUT',
				contentType: 'application/json; charset=utf-8',
				dataType: 'json',
				processData: false,
				headers: adapter.defineHeaders(),
				data: JSON.stringify(contentItem),
				global: false /* Stop global ajaxError event from triggering */
			};
			console.log("saveContent adapter " + namespace);
			return _ember['default'].$.ajax(namespace, options);
		},

		defineHeaders: function defineHeaders() {
			var sessionData = this.get('session').get('data');
			var token = sessionData.authenticated.token;
			//let token = "MTQ4MzYzNzcwMjY3Mzo3MjQwZDBiMS1lM2I0LTRmMDgtOTMwMC1mMGE2ZmJlMjljMWQ6YmE5NTZhOTctYWUxNS0xMWU1LWEzMDItZjhhOTYzMDY1OTc2";

			console.log('token is ' + token);
			return {
				'Authorization': 'Token ' + token
			};
		}

	});
});