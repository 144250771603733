define('llama-table/components/llama-footer/component', ['exports', 'ember', 'llama-table/components/llama-footer/template'], function (exports, _ember, _llamaTableComponentsLlamaFooterTemplate) {
	'use strict';

	var get = _ember['default'].get;
	var set = _ember['default'].set;
	var observer = _ember['default'].observer;
	var computed = _ember['default'].computed;
	var alias = computed.alias;
	var filterBy = computed.filterBy;

	var LlamaFooter = _ember['default'].Component.extend({
		layout: _llamaTableComponentsLlamaFooterTemplate['default'],
		classNames: 'llama-footer',
		itemViewClass: alias('root.FooterColumngroupView'),
		columngroupViews: filterBy('childViews', 'isVisible', true),
		contentBinding: 'columngroups',
		scrollTop: alias('root.scrollTop'),

		columngroups: null,
		rows: null,

		didConstruct: false,

		data: computed('root.footerController', {
			get: function get(key, val, old) {
				if (old) {
					old.destroy();
				}
				var footerController = this.get('root.footerController');
				var Constructor, instance;
				if (typeof footerController === 'function') {
					Constructor = footerController;
					instance = Constructor.create({
						content: this.get('rows')
					});
					this.set('didConstruct', true);
				} else if (footerController) {
					instance = footerController;
					this.set('didConstruct', false);
				}
				return instance;
			}
		}),

		didInsertElement: function didInsertElement() {
			this._super();
			this.updateScrollPosition();
		},

		createChildView: function createChildView(View, attrs) {
			var data = this.get('data');
			var columns = get(attrs, 'content');
			set(attrs, 'root', this.get('root'));
			set(attrs, 'columns', columns);
			set(attrs, 'data', data);
			return this._super(View, attrs);
		},

		updateScrollPosition: observer('scrollTop', function () {
			var $footer = _ember['default'].$(this.$());
			$footer.css('marginBottom', this.get('scrollTop') * -1);
		}),

		willDestroy: function willDestroy() {
			if (this.get('didConstruct')) {
				var footerController = this.get('data');
				footerController.destroy();
			}
			this._super();
		}
	});

	exports['default'] = LlamaFooter;
});