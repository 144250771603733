define('llama-table/controllers/resize', ['exports', 'ember'], function (exports, _ember) {
	'use strict';

	/**
  * Used for starting and stopping resize events and monitoring the mouse
  *   position in-between.
  * @module controllers
  * @constructor
  * @class ResizeController
  * @extends Ember.Controller
  */
	var ResizeController = _ember['default'].Controller.extend({
		eventBegin: null,
		deltaX: 0,
		deltaY: 0,
		init: function init() {
			this.stopResize = this.stopResize.bind(this);
			this.handleResize = this.handleResize.bind(this);
		},
		startResize: function startResize(e) {
			this.set('eventBegin', e);
			_ember['default'].$(window).one('mouseup', this.stopResize).on('mousemove', this.handleResize);
		},
		handleResize: function handleResize(e) {
			var begin = this.get('eventBegin');
			var beginPageX = begin.pageX;
			var beginPageY = begin.pageY;
			this.set('deltaX', e.pageX - beginPageX);
			this.set('deltaY', e.pageY - beginPageY);
		},
		stopResize: function stopResize() {
			_ember['default'].$(window).off('mousemove', this.handleResize);
			this.set('eventBegin', null);
		},
		actions: {
			startResize: function startResize(e) {
				this.startResize(e);
			}
		}
	});

	exports['default'] = ResizeController;
});