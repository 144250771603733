define('llama-table/mixins/scroll-handler', ['exports', 'ember'], function (exports, _ember) {
	'use strict';

	/**
  * Enables custom 'scroll' event.
  * @module mixins
  * @constructor
  * @class ScrollHandlerMixin
  * @extends Ember.Mixin
  */
	var ScrollHandlerMixin = _ember['default'].Mixin.create({
		didInsertElement: function didInsertElement() {
			var _this = this;

			this._super();
			this.$().on('scroll.scroll-handler-mixin', function (e) {
				_this.handleEvent('scroll', e);
			});
		},
		willDestroyElement: function willDestroyElement() {
			this.$().off('scroll.scroll-handler-mixin');
			this._super();
		}
	});

	exports['default'] = ScrollHandlerMixin;
});